import { internalSetCookie } from '../utils/cookies.js';

const handleDeveloperMode = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const developerMode = urlSearchParams.get("developer_mode");
    if (developerMode) {
        internalSetCookie('developer_mode', developerMode);
    }
}

handleDeveloperMode();