import {
    activateSignUpSecondStep, disableSubmitButton,
    isFilledInputs
} from "../js/popups/activate_forms";
import {
    getValueFromBrowser,
    setSignUpBanner,
} from "./trackingQueryHook";
import { sendGtagEvent } from "./gtag";
import { validateAllFirstStepInputs, validateAllSecondStepInputs, validateFirstStep } from "./validate";
import { internalSetCookie, internalGetCookie } from "./cookies";
import getMessage from "./getMessage";

const resetInputs = (obj) => {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            resetInputs(obj[key]);
        } else {
            obj[key] = false;
        }
        if (key === '') {
            delete obj[key];
        }
    }
}

const resetErrors = () => {
    const errors = document.querySelectorAll('.error__message');
    const dateErrors = document.querySelectorAll('.signup__birthdate__row .error-message');
    const dobError = document.querySelector('.error__message.signup-dob');
    [...errors, ...dateErrors, dobError].forEach((error) => {
        error.innerHTML = '';
        error.classList.remove('show');
    });
}

const resetSignUpSteps = () => {
    const firstStep = document.querySelector('#SignUp .first-step-section');
    const secondStep = document.querySelector('#SignUp .second-step-section');
    const progressIndicatorBackIcon = document.querySelector('.progress-indicator-back-icon');
    const progressIndicator = document.querySelector('.progress-indicator');
    const progressIndicatorLine = document.querySelector('.progress-indicator-line');
    const progressIndicatorInactive = document.querySelector('.progress-indicator_inactive');
    const banner = document.querySelector('#SignUp .popup__banner');
    const button = document.querySelector('#signup-form-submit-button');
    const authContainer = document.querySelector('#signup-form .auth-container');

    if (!firstStep.classList.contains('is__active')) {
        if (authContainer) {
            authContainer.classList.remove('hide');
        }
        secondStep.classList.remove('is__active');
        firstStep.classList.add('is__active');
        [progressIndicator, progressIndicatorLine, progressIndicatorInactive, banner, progressIndicatorBackIcon].forEach((elem) => {
            elem?.classList.remove('second-step');
        });
        progressIndicatorBackIcon.removeEventListener('click', resetSignUpSteps);
        progressIndicator.removeEventListener('click', resetSignUpSteps);
        resetErrors();
        saveSignUpFormData();
        button.disabled = false;
    }
}

export const eventCleanupFunctions = [];

export const saveSignUpFormData = () => {
    const signUpForm = document.querySelector('#SignUp')
    const signUpInputs = signUpForm.querySelectorAll('#SignUp input');
    const savedSignUpFormData = {};
    for (let i = 0; i < signUpInputs.length; i++) {
        const input = signUpInputs[i];
        let inputKey = input.id;
        inputKey = inputKey.replace('signup-', '');
        if (inputKey !== '' && input.value) {
            savedSignUpFormData[inputKey] = input.value;
        }
    }
    const day = signUpForm.querySelector('#signup-day');
    if (day && day.dataset.value) {
        savedSignUpFormData.day = day.getAttribute('data-value');
    }
    const month = signUpForm.querySelector('#signup-month');
    if (month && month.dataset.value) {
        savedSignUpFormData.month = month.getAttribute('data-value');
    }
    const year = signUpForm.querySelector('#signup-year');
    if (year && year.dataset.value) {
        savedSignUpFormData.year = year.getAttribute('data-value');
    }
    const firstStep = signUpForm.querySelector('.first-step-section');
    savedSignUpFormData.currentStep = firstStep.classList.contains('is__active') ? 1 : 2;

    internalSetCookie("savedSignUpFormData", JSON.stringify(savedSignUpFormData));
}

const closeAllPopups = () => {
    document.querySelectorAll('[data-popup].is__open').forEach((openPopup) => {
        const modalFromUrl = getQueryParamFromUrl('modal');
        if (modalFromUrl) {
            removeQueryParamFromUrl('modal');
        }
        if (openPopup.id === 'SignUp') {
            const day = openPopup.querySelector('#signup-day');
            if (day && day.dataset.value) {
                day.removeAttribute('data-value');
            }
            const month = openPopup.querySelector('#signup-month');
            if (month && month.dataset.value) {
                month.removeAttribute('data-value');
            }
            const year = openPopup.querySelector('#signup-year');
            if (year && year.dataset.value) {
                year.removeAttribute('data-value');
            }
            sendGtagEvent("sign_up_form_closed", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
            resetSignUpSteps();
            const signUpButton = document.querySelector('#signup-form-submit-button');
            signUpButton.disabled = true;
            eventCleanupFunctions.forEach(cleanupFn => cleanupFn());
            eventCleanupFunctions.length = 0;
        } else if (openPopup.id === 'Login') {
            sendGtagEvent("login_form_closed", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
            const loginButton = document.querySelector('#login-form-submit-button');
            loginButton.disabled = true;
        }
        if (openPopup.id === 'ForgotPassword' || openPopup.id === 'SignUp') {
            const hintPasswordElements = document.querySelectorAll('.signup-hint-password');
            hintPasswordElements.forEach((hint) => {
                hint.classList.remove('success');
                hint.classList.remove('unsuccessful');
            });
        }
        openPopup.classList.remove('is__open');
        const forms = document.querySelectorAll('form');
        forms.forEach((form) => form.reset());
        resetInputs(isFilledInputs);
        resetErrors();
    });
};

const openPopupById = (popupId) => {
    const popup = document.getElementById(popupId);
    if (popup) {
        if (popupId === 'SignUp') {
            sendGtagEvent("sign_up_form_opened", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
            setSignUpBanner(popup);
            const savedSignUpFormData = internalGetCookie("savedSignUpFormData");
            if (savedSignUpFormData) {
                const savedSignUpFormDataObject = JSON.parse(decodeURIComponent(savedSignUpFormData));
                if (savedSignUpFormDataObject) {
                    const signUpSubmitButton = popup?.querySelector('#signup-form-submit-button');

                    if ('email' in savedSignUpFormDataObject) {
                        const emailInput = popup?.querySelector('#signup-email');
                        if (emailInput) {
                            emailInput.value = savedSignUpFormDataObject.email;
                        }
                    }
                    if ('password' in savedSignUpFormDataObject) {
                        const passwordInput = popup?.querySelector('#signup-password');
                        if (passwordInput) {
                            passwordInput.value = savedSignUpFormDataObject.password;
                        }
                    }
                    if ('phoneNumber' in savedSignUpFormDataObject) {
                        const phoneNumberInput = popup?.querySelector('#signup-phoneNumber');
                        if (phoneNumberInput) {
                            phoneNumberInput.value = savedSignUpFormDataObject.phoneNumber;
                        }
                    }
                    if ('firstName' in savedSignUpFormDataObject) {
                        const firstNameInput = popup?.querySelector('#signup-firstName');
                        if (firstNameInput) {
                            firstNameInput.value = savedSignUpFormDataObject.firstName;
                        }
                    }
                    if ('lastName' in savedSignUpFormDataObject) {
                        const lastNameInput = popup?.querySelector('#signup-lastName');
                        if (lastNameInput) {
                            lastNameInput.value = savedSignUpFormDataObject.lastName;
                        }
                    }
                    if ('couponCode' in savedSignUpFormDataObject) {
                        const couponCodeeInput = popup?.querySelector('#signup-couponCode');
                        if (couponCodeeInput) {
                            couponCodeeInput.value = savedSignUpFormDataObject.couponCode;
                        }
                    }
                    if ('day' in savedSignUpFormDataObject) {
                        const dayElement = popup?.querySelector('#signup-day');
                        if (dayElement && savedSignUpFormDataObject.day) {
                            dayElement.innerHTML = savedSignUpFormDataObject.day;
                            dayElement.setAttribute('data-value', savedSignUpFormDataObject.day);
                        }
                    }
                    if ('month' in savedSignUpFormDataObject) {
                        const monthElement = popup?.querySelector('#signup-month');
                        const monthValue = savedSignUpFormDataObject.month;

                        if (monthElement && monthValue) {
                            const monthItem = popup?.querySelector(`.select__list#month [data-value="${monthValue}"]`);
                            const monthItemValue = monthItem?.getAttribute('data-get-message');

                            monthElement.innerHTML = getMessage(monthItemValue, monthElement);
                            monthElement.setAttribute('data-value', monthValue);
                        }
                    }
                    if ('year' in savedSignUpFormDataObject) {
                        const yearElement = popup?.querySelector('#signup-year');
                        if (yearElement && savedSignUpFormDataObject.year) {
                            yearElement.innerHTML = savedSignUpFormDataObject.year;
                            yearElement.setAttribute('data-value', savedSignUpFormDataObject.year);
                        }
                    }
                    if ('currentStep' in savedSignUpFormDataObject) {
                        const popupInputs = popup.querySelectorAll("input");
                        popupInputs.forEach(input => {
                            if (input.name in isFilledInputs.signUpFormFirstStep) {
                                isFilledInputs.signUpFormFirstStep[input.name] = input.value.length > 0;
                            }
                        });

                        disableSubmitButton(signUpSubmitButton, false);

                        if (savedSignUpFormDataObject.currentStep === 1) {
                            const { addEventListeners, removeEventListeners } = validateFirstStep();
                            addEventListeners(true);
                            eventCleanupFunctions.push(removeEventListeners);

                            signUpSubmitButton.addEventListener('click', () => {
                                setTimeout(() => {
                                    validateAllSecondStepInputs();
                                }, 0);
                            });
                        } else {
                            const firstNameInput = popup.querySelector("#signup-firstName");
                            const firstNameErrorContainer = popup.querySelector(".error__message.signup-first-name");
                            const lastNameInput = popup.querySelector("#signup-lastName");
                            const lastNameErrorContainer = popup.querySelector(".error__message.signup-last-name");
                            const dateFields = [...popup.querySelectorAll(".select__screen")];
                            const dateFieldsErrorMessage = popup.querySelectorAll('.signup__birthdate__row .error-message');
                            activateSignUpSecondStep(
                                signUpSubmitButton,
                                [firstNameInput, lastNameInput],
                                dateFields,
                                [firstNameErrorContainer, lastNameErrorContainer],
                                dateFieldsErrorMessage,
                            );
                            validateAllSecondStepInputs();
                        }

                        validateAllFirstStepInputs();
                    }
                }
            } else {
                const { addEventListeners, removeEventListeners } = validateFirstStep();
                addEventListeners();
                eventCleanupFunctions.push(removeEventListeners);
            }
        } else if (popupId === 'Login') {
            sendGtagEvent("login_form_opened", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
        }
        popup.classList.add('is__open');
        addQueryParamToUrl('modal', popupId);
    }
};

const getQueryParamFromUrl = (key) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
}

const addQueryParamToUrl = (key, value) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);
    const urlPath = window.location.pathname;
    const urlHash = window.location.hash;
    const newUrl = `${urlPath}?${params.toString()}${urlHash}`;
    window.history.replaceState(null, null, newUrl);
};

const removeQueryParamFromUrl = (key) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(key);
    const hasQueryParams = params.toString();
    const urlPath = window.location.pathname;
    const urlHash = window.location.hash;
    const newUrl = `${urlPath}${hasQueryParams ? `?${params.toString()}` : ''}${urlHash}`;
    window.history.replaceState(null, null, newUrl);
};

const checkIfAuthPopup = (popupId) => {
    return popupId === 'SignUp' || popupId === 'Login' || popupId === 'ForgotPassword';
};

const removeAuthIfSSO = () => {
    const ssoFromUrl = getQueryParamFromUrl('sso');
    const authContainers = document.querySelectorAll('.auth-container');

    if (ssoFromUrl === '1') return;
    authContainers.forEach(authContainer => {
        authContainer.remove();
    });
}

removeAuthIfSSO();

export {
    resetSignUpSteps,
    closeAllPopups,
    openPopupById,
    removeQueryParamFromUrl,
    getQueryParamFromUrl,
    checkIfAuthPopup
};
