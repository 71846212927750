import {openPopupById, closeAllPopups, getQueryParamFromUrl, checkIfAuthPopup} from "../../utils/togglePopup";
import {setValueToBrowser} from "../../utils/trackingQueryHook";

const handleTogglePopup = () => {
    document.querySelectorAll('[data-open-popup]').forEach((button) => {
        button.addEventListener('click', () => {
            const popupId = button.dataset.openPopup;
            if (popupId === 'quitPopup') {
                openPopupById(popupId);
                return;
            } else if ( popupId === 'SignUp') {
                const product = button.dataset.product;
                if (product) {
                    setValueToBrowser('product', product === 'sport' ? 2 : 1) ;
                }
            }
            closeAllPopups();
            openPopupById(popupId);
        });
    });
    
    document.querySelectorAll('[data-close-popup]').forEach((closeButton) => {
        closeButton.addEventListener('click', (e) => {
            e.stopPropagation();
            const typeAttr = closeButton.getAttribute('data-type-popup');
            if (typeAttr === 'quitPopup') {
                document.querySelector('[data-type-popup].is__open').classList.remove('is__open');
                return;
            }

            if (e?.target === e?.currentTarget || e?.currentTarget?.tagName?.toLowerCase() === 'button') {
                if (e?.target?.closest('#SignUp')) {
                    openPopupById('quitPopup');
                    return
                }
                closeAllPopups();
            }
        });
    });

    const modalFromUrl = getQueryParamFromUrl('modal');
    if (modalFromUrl && checkIfAuthPopup(modalFromUrl)) {
        openPopupById(modalFromUrl);
    }
};

handleTogglePopup();
