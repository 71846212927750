const popup = {
    en: {
        "popup.login.title": "Login",
        "popup.login.field.usernameOrEmail": "User or Email",
        "popup.login.field.password": "Password",
        "popup.login.checkbox.rememberMe": "Remember me",
        "popup.login.button.forgotPassword": "Have you forgotten your password?",
        "popup.login.button.submit": "Login",
        "popup.login.text.create.account": "New to Winpot?",
        "popup.login.button.create.account": "Create account",
        "popup.login.text.agreedment": "Registering or creating your account means you agree to ",
        "popup.login.button.agreedment.link1": "The terms and conditions",
        "popup.login.button.agreedment.link2": "Privacy policy",

        "popup.signup.title": "OPEN AN ACCOUNT!",
        "popup.signup.field.email": "Email <span>(To receive exclusive offers)</span>",
        "popup.signup.field.password": "Password",
        "popup.signup.field.password.hint.1": "8-30 characters",
        "popup.signup.field.password.hint.2": "No spaces",
        "popup.signup.field.password.hint.3": "A number",
        "popup.signup.field.password.hint.4": "An uppercase letter",
        "popup.signup.field.password.hint.5": "A lowercase letter",
        "popup.signup.field.phone": "Phone number <span>(an activation code will be sent)</span>",
        "popup.signup.field.firstName": "First name <span>(As it appears on your ID)</span>",
        "popup.signup.field.lastName": "Last name <span>(As it appears on your ID)</span>",
        "popup.signup.field.dateOfBirth": "Date of birth",
        "popup.signup.field.day": "Day",
        "popup.signup.field.month": "Month",
        "popup.signup.field.year": "Years",
        "popup.signup.field.month.january": "January",
        "popup.signup.field.month.february": "February",
        "popup.signup.field.month.march": "March",
        "popup.signup.field.month.april": "April",
        "popup.signup.field.month.may": "May",
        "popup.signup.field.month.june": "June",
        "popup.signup.field.month.july": "July",
        "popup.signup.field.month.august": "August",
        "popup.signup.field.month.september": "September",
        "popup.signup.field.month.october": "October",
        "popup.signup.field.month.november": "November",
        "popup.signup.field.month.december": "December",
        "popup.signup.couponCode.title": "Got <span id='couponButton' class='signup__coupon__code__button'>coupon</span> code ? (opcional)",
        "popup.signup.couponCode.label": "Coupon",
        "popup.signup.checkbox": "I agree to receive newsletters, promotional information and product updates by email or SMS",
        "popup.signup.button.submit": "Next",
        "popup.signup.text.alreadyHaveAccount": "AlreadyHaveAccount?",
        "popup.signup.button.login": "Login",
        "popup.signup.or": "or",

        "popup.forgotPass.title.question": "Have you forgotten your password?",
        "popup.forgotPass.button.resetViaEmail": "Reset via Email",
        "popup.forgotPass.button.resetViaPhone": "Reset via SMS",
        "popup.forgotPass.subtitle.inputEmail": "Enter your email address",
        "popup.forgotPass.subtitle.inputPhone": "Enter your phone number",
        "popup.forgotPass.text.sendViaEmail": "Send email",
        "popup.forgotPass.button.send": "Send",
        "popup.forgotPass.title.thank": "THANK YOU!",
        "popup.forgotPass.subtitle.congrats": "Please check your email to<br>reset your password",
        "popup.forgotPass.text.phone": "Phone number <span>(An activation code will be sent)</span>",
        "popup.forgotPass.text.phone.hint": "Text messages (SMS) can only be used if the mobile phone number has been verified.",
        "popup.forgotPass.title.incorrect": "The phone number provided<br>is not verified or does not exist",
        "popup.forgotPass.title.attempts": "The option to recover your password with SMS is currently not available",
        "popup.forgotPass.button.continueViaEmail": "Continue Email",
        "popup.forgotPass.button.continueViaPhone": "Try again",
        "popup.forgotPass.title.congrats": "Congratulations!",
        "popup.forgotPass.subtitle.passwordChanged": "Your password has been changed",
        "popup.forgotPass.subtitle.checkSMS": "We have sent an SMS code to your phone.<br><span id='forgotPass__user__phone'></span>",
        "popup.forgotPass.text.sms.hint": "Are you having problems?<br>Contact<button data-open-support>support</button>",
        "popup.forgetPass.title.reset": "Reset password",
        "popup.forgetPass.text.newPass": "New Password",
        "popup.forgetPass.text.newPassCopy": "Repeat password",

        "popup.quitPopup.support": "Need help?",
        "popup.quitPopup.title.top": "Are you sure you want to cancel your registration?",
        "popup.quitPopup.title.center": "By canceling, you’ll miss out on exclusive promotions, exciting benefits, and all the fun of playing with us.",
        "popup.quitPopup.title.bottom": "Do you still want to cancel?",
        "popup.quitPopup.button.close.step": "Continue Registration",
        "popup.quitPopup.button.close.all": "Cancel Registration",
    },
    es: {
        "popup.login.title": "Acceder",
        "popup.login.field.usernameOrEmail": "Usuario o Correo Electrónico",
        "popup.login.field.password": "Contraseña",
        "popup.login.checkbox.rememberMe": "Recuérdame",
        "popup.login.button.forgotPassword": "¿Has olvidado tu contraseña?",
        "popup.login.button.submit": "Acceder",
        "popup.login.text.create.account": "Nuevo en Winpot?",
        "popup.login.button.create.account": "Crear cuenta",
        "popup.login.text.agreedment": "Registrate o crear tu cuenta significa que estas de acuerdo con ",
        "popup.login.button.agreedment.link1": "Los terminos y condiciones",
        "popup.login.button.agreedment.link2": "La politica de privacidad",
        "popup.signup.or": "o",

        "popup.signup.title": "ABRIR UNA CUENTA!",
        "popup.signup.field.email": "Correo electrónico <span>(Para recibir ofertas exclusivas)</span>",
        "popup.signup.field.password": "Contraseña",
        "popup.signup.field.password.hint.1": "8-30 caracteres",
        "popup.signup.field.password.hint.2": "Sin espacios",
        "popup.signup.field.password.hint.3": "Un número",
        "popup.signup.field.password.hint.4": "Una letra mayúscula",
        "popup.signup.field.password.hint.5": "Una letra minúscula",
        "popup.signup.field.phone": "Número de teléfono <span>(Se enviara un codigo de activacion)</span>",
        "popup.signup.field.firstName": "Nombre <span>(Como aparece en tu identificacion)</span>",
        "popup.signup.field.lastName": "Apellido <span>(Como aparece en tu identificacion)</span>",
        "popup.signup.field.dateOfBirth": "Fecha de nacimiento",
        "popup.signup.field.day": "Día",
        "popup.signup.field.month": "Mes",
        "popup.signup.field.year": "Año",
        "popup.signup.field.month.january": "Enero",
        "popup.signup.field.month.february": "Feb",
        "popup.signup.field.month.march": "Mar",
        "popup.signup.field.month.april": "Abr",
        "popup.signup.field.month.may": "Mayo",
        "popup.signup.field.month.june": "Jun",
        "popup.signup.field.month.july": "Jul",
        "popup.signup.field.month.august": "Agosto",
        "popup.signup.field.month.september": "Sept",
        "popup.signup.field.month.october": "Oct",
        "popup.signup.field.month.november": "Nov",
        "popup.signup.field.month.december": "Dic",
        "popup.signup.couponCode.title": "¿Tienes código de <span id='couponButton' class='signup__coupon__code__button'>cupón</span>? (opcional)",
        "popup.signup.couponCode.label": "Cupón",
        "popup.signup.checkbox": "Acepto recibir boletines, información promocional y actualizaciones de productos por correo electrónico o sms",
        "popup.signup.button.submit": "Siguiente",
        "popup.signup.text.alreadyHaveAccount": "¿Ya tiene una cuenta?",
        "popup.signup.button.login": "Acceder",

        "popup.forgotPass.title.question": "¿Has olvidado tu contraseña?",
        "popup.forgotPass.button.resetViaEmail": "Restablecer a través de Email",
        "popup.forgotPass.button.resetViaPhone": "Restablecer a través de SMS",
        "popup.forgotPass.subtitle.inputEmail": "Introduzca su dirección de correo electrónico",
        "popup.forgotPass.subtitle.inputPhone": "Introduzca su número de teléfono",
        "popup.forgotPass.text.sendViaEmail": "Enviar correo electrónico",
        "popup.forgotPass.button.send": "Enviar",
        "popup.forgotPass.title.thank": "¡GRACIAS!",
        "popup.forgotPass.subtitle.congrats": "Por favor revisa tu correo electrónico para<br>restablecer tu contraseña",
        "popup.forgotPass.text.phone": "Número de teléfono <span>(Se enviara un codigo de activacion)</span>",
        "popup.forgotPass.text.phone.hint": "Los mensajes de texto (SMS) solo se pueden usar si el número de teléfono móvil ha sido verificado.",
        "popup.forgotPass.title.incorrect": "El número de teléfono proporcionado<br>no está verificado o no existe",
        "popup.forgotPass.title.attempts": "Actualmente la opción de recuperar tu<br>contraseña con SMS no está disponible",
        "popup.forgotPass.button.continueViaEmail": "Continuar con correo electrónico",
        "popup.forgotPass.button.continueViaPhone": "Inténtalo de nuevo",
        "popup.forgotPass.title.congrats": "¡Enhorabuena!",
        "popup.forgotPass.subtitle.passwordChanged": "Su contraseña ha sido cambiada",
        "popup.forgotPass.subtitle.checkSMS": "Hemos enviado un código SMS a tu teléfono.<br><span id='forgotPass__user__phone'></span>",
        "popup.forgotPass.text.sms.hint": "Tiene problemas? Pongase en contacto con el<br><button data-open-support>servicio de asistencia</button>",
        "popup.forgetPass.title.reset": "Restablecer contraseña",
        "popup.forgetPass.text.newPass": "Nueva Contraseña",
        "popup.forgetPass.text.newPassCopy": "Repetir contraseña",

        "popup.quitPopup.support": "¿Necesitas ayuda?",
        "popup.quitPopup.title.top": "¿Estás seguro de que quieres cancelar tu registro?",
        "popup.quitPopup.title.center": "Al cancelar, perderás promociones exclusivas, emocionantes beneficios y toda la diversión de jugar con nosotros.",
        "popup.quitPopup.title.bottom": "¿Aún quieres cancelar?",
        "popup.quitPopup.button.close.step": "No, continuar con el registro",
        "popup.quitPopup.button.close.all": "Sí, cancelar registro",
    },
};

export default popup;