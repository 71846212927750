import { userHasAccount } from "./utils";

const signUpHeaderButton = document.querySelector('.header__button__signup');
const loginHeaderButton = document.querySelector('.header__button__login');
const choicesCasinoSignUpButton = document.querySelector('.main__choices button[data-get-message="main.choices.casino.button.signup"]');
const choicesSportSignUpButton = document.querySelector('.main__choices button[data-get-message="main.choices.sport.button.signup"]');
const choicesCasinoLoginButton = document.querySelector('.main__choices button[data-get-message="main.choices.casino.button.login"]');
const choicesSportLoginButton = document.querySelector('.main__choices button[data-get-message="main.choices.sport.button.login"]');

if (userHasAccount()) {
  signUpHeaderButton?.classList.remove('header__button_green');
  signUpHeaderButton?.classList.add('header__button_green-outlined');
  loginHeaderButton?.classList.remove('header__button_green-outlined');
  loginHeaderButton?.classList.add('header__button_green');

  choicesCasinoSignUpButton?.classList.add('hidden');
  choicesCasinoLoginButton?.classList.remove('hidden');
  choicesSportSignUpButton?.classList.add('hidden');
  choicesSportLoginButton?.classList.remove('hidden');
}
