import { saveSignUpFormData } from "../../utils/togglePopup";
import { validateFirstStepInput } from "../../utils/validate";
import { emailValidation } from "../../utils/validation";
import { checkSignUpInputsErrors } from "./activate_forms";

const signupForm = document.querySelector('#SignUp');
const signupEmail = signupForm.querySelector('#signup-email');
const emailSelectList = document.querySelector("#email-select-list");
const fieldsErrorMessage = signupForm.querySelectorAll(".error__message");
const hintPasswordElements = [...signupForm.querySelectorAll('.signup-hint-password')];
const dateFieldsErrorMessage = signupForm.querySelectorAll('.signup__birthdate__row .error-message');
const signUpSubmitButton = document.getElementById('signup-form-submit-button');
const emailErrorContainer = signupForm.querySelector(".error__message.signup-email");

const emailDomains = [
    "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "icloud.com",
    "mail.ru", "aol.com", "zoho.com", "protonmail.com", "yandex.com"
];

const addYearsToInput = () => {
    const yearElement = document.getElementById('year'); // get the year select
    const df = document.createDocumentFragment(); // create a document fragment to hold the options while we create them
    const years = Array.from({ length: new Date().getFullYear() - 18 - 1920 + 1 }, (_, ind) => {
        return ind + 1920;
    });
    for (let year = 0; year < years.length; year++) {
        const yearStrValue = `${years[year]}`;
        const option = document.createElement('li'); // create the option element
        option.classList.add('select__item');
        option.setAttribute('data-value', yearStrValue);
        option.appendChild(document.createTextNode(yearStrValue)); // set the textContent in a safe way.
        df.appendChild(option); // append the option to the document fragment
    }
    yearElement?.appendChild(df); // append the document fragment to the DOM. this is the better way rather than setting innerHTML a bunch of times (or even once with a long string)
}

const addCustomSelectDateOfBirth = () => {
    const selectScreen = document.querySelectorAll('.js-select-screen');
    const selectItem = document.querySelectorAll('.select__item');

    selectScreen.forEach(item => {
        item.addEventListener('click', e => {
            const screen = e.target.closest('.js-select-screen');
            if (screen) {
                if (screen.classList.contains('is-active') && screen.id !== 'signup-email') {
                    screen.nextElementSibling.classList.toggle('is-open');
                    screen.classList.toggle('is-active');
                    return
                }
                selectScreen.forEach(item => {
                    item.classList.remove('is-active');
                    item.nextElementSibling.classList.remove('is-open');
                });
                screen.nextElementSibling.classList.toggle('is-open');
                screen.classList.toggle('is-active');
                if (screen.id === 'screen-year') {
                    screen.nextElementSibling.lastChild.scrollIntoView();
                }
            } else {
                const screenActive = document.querySelector('.js-select-screen.is-active');
                if (screenActive) {
                    screenActive.classList.remove('is-active');
                    screenActive.nextElementSibling.classList.remove('is-open');
                }

            }
        });
    });

    selectItem.forEach(item => {
        item.addEventListener('click', toggleSelectItem);
    });
};

const toggleSelectItem = (e) => {
    const selectedItem = e.target.closest('.select__item');

    if (selectedItem) {
        selectedItem.classList.add('is-active');
        const parentList = selectedItem.closest('.js-select-list'),
            screen = parentList.previousElementSibling;
        const attr = screen.getAttribute('data-validation');

        if (screen) {
            const fieldInnerHTML = selectedItem.innerHTML;
            if (typeof fieldInnerHTML !== 'undefined' && screen?.tagName !== "INPUT") {
                screen.innerHTML = fieldInnerHTML;
            } else if (screen?.tagName === "INPUT" && attr === 'email') {
                screen.value = fieldInnerHTML;
                validateFirstStepInput(screen);
                if (emailErrorContainer.innerHTML === '' && fieldInnerHTML.length > 0) {
                    emailValidation(fieldInnerHTML.trim(), emailErrorContainer);
                }

                checkSignUpInputsErrors(
                    'signUpFormFirstStep',
                    signUpSubmitButton,
                    [...fieldsErrorMessage, ...dateFieldsErrorMessage],
                    hintPasswordElements
                );
            }
            const fieldValue = selectedItem.getAttribute('data-value');
            if (typeof fieldValue !== 'undefined') {
                screen.setAttribute('data-value', fieldValue);
            }
            saveSignUpFormData();
        }

        if (parentList) {
            let items = parentList.querySelectorAll('.select__item');

            items?.forEach(item => {
                item.classList.remove('is-active');
            });

            if (parentList.classList.contains('is-open')) {
                parentList.classList.remove('is-open');
                screen.classList.remove('is-active');
            }
        }
    }
}

const closeEmailSelectOnClick = (event) => {
    if (!emailSelectList) return;

    if (!event.target.closest("#signup__row__email>div")) {
        emailSelectList.classList.remove("is-open");
    }
}

const displayEmailHints = (e) => {
    if (!emailSelectList) return;

    const inputValue = e.target.value;
    const inputValueDomain = inputValue.split('@')[1] || '';

    const filteredEmailDomains = emailDomains.filter(emailDomain =>
        inputValue && emailDomain.startsWith(inputValueDomain) && emailDomain !== inputValueDomain
    );

    emailSelectList.innerHTML = '';

    const emailWithoutDomain = inputValue.includes('@')
        ? inputValue.substring(0, inputValue.lastIndexOf('@'))
        : inputValue;

    const fragment = document.createDocumentFragment();

    filteredEmailDomains.forEach(domain => {
        const email = `${emailWithoutDomain}@${domain}`;
        const li = document.createElement('li');
        li.classList.add('select__item');
        li.setAttribute('data-value', email);
        li.textContent = email;
        fragment.appendChild(li);
    });

    emailSelectList.appendChild(fragment);
}

emailSelectList?.addEventListener("click", (event) => {
    const item = event.target.closest('.select__item');
    if (item) toggleSelectItem(event);
});

addYearsToInput();
addCustomSelectDateOfBirth();
signupForm.addEventListener("click", closeEmailSelectOnClick);
signupEmail.addEventListener("focus", displayEmailHints);
signupEmail.addEventListener("input", displayEmailHints);