import {WINPOT_CASINO_URL} from "../utils/consts";
import {checkIsSportUser} from "../utils/trackingQueryHook";

const redirectToWebsiteWithProduct = () => {
    window.location.href = `${WINPOT_CASINO_URL}${checkIsSportUser() ? 'sport' : 'casino'}?modal=LoginPromo`
}

const findGamificationSpecialLinks = () => {
    const gamificationStoreItems = document.querySelectorAll('.gamification_special_item');
    if (gamificationStoreItems && gamificationStoreItems?.length > 0) {
        gamificationStoreItems.forEach((item) => {
            item.addEventListener('click', redirectToWebsiteWithProduct);
        })
    }
}

findGamificationSpecialLinks();