import getImageSrc from "../utils/getImgSrc";

const setAllImgSrcs = () => {
    document.querySelectorAll('[data-img-src-key]').forEach((img) => {
        img.src = getImageSrc(img.dataset.imgSrcKey);
    })
};

setAllImgSrcs();

let resize;

const updateOnEvent = () => {
  clearTimeout(resize);
  resize = setTimeout(setAllImgSrcs, 100); 
};

window.addEventListener('resize', updateOnEvent);
window.addEventListener('reload', updateOnEvent);