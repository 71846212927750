import {AffiliateProduct, getUserProduct} from "./trackingQueryHook.js";
import {internalGetCookie, internalRemoveCookie, internalSetCookie} from "./cookies.js";
import {APP_MODE, APP_SERVER_URL, APP_WINPOT_CASINO} from "../../../config";
import {AddOrRemoveSpinner} from "./utils";

let currentDomain;
switch(APP_MODE) {
	case "dev":
		currentDomain = "localhost";
		break;
	case "prod":
		currentDomain = "winpot.mx";
		break;
	case "stage":
	default:
		currentDomain = "stage-winpot.mx";
		break;
}

const REFRESH_TOKEN_MAX_LIFETIME = 2147483647;

let refreshTimeout;
let activityCheckInterval;

export const isRememberUser = () => {
	if (typeof window !== "undefined") {
		return localStorage.getItem("rememberUser") ? localStorage.getItem("rememberUser") === "remember" : false;
	} else {
		return false;
	}
}

const initializeSession = (sessionData = null) => {
	// Set default language to Spanish. In order to override the accept-language header we need to set the required default language
	internalSetCookie("NEXT_LOCALE", "es");
	if (sessionData) {
		internalSetCookie('tokenExpirationDate', sessionData.expiresIn.tokenExpirationDate);
		internalSetCookie('AuthorizationToken', `Bearer ${sessionData.accessToken}`);
		internalSetCookie('RefreshToken', `Bearer ${sessionData.refreshToken}`);
	}

	const tokenExpirationDate = parseInt(internalGetCookie('tokenExpirationDate'));

	if (!tokenExpirationDate || tokenExpirationDate <= new Date().getTime()) {
		internalRemoveCookie('tokenExpirationDate');
		internalRemoveCookie('AuthorizationToken');
		clearTimeout(refreshTimeout);
		return null;
	}

	if (!refreshTimeout && isRememberUser()) {
		let refreshTokenTimeout = Math.round((tokenExpirationDate - (new Date()).getTime()) * 0.8);
		if (refreshTokenTimeout < 0) {
			return;
		} else if (refreshTokenTimeout > REFRESH_TOKEN_MAX_LIFETIME) {
			refreshTokenTimeout = REFRESH_TOKEN_MAX_LIFETIME;
		}
		refreshTimeout = setTimeout(() => {
			fetch(`${APP_SERVER_URL}player/crm/refreshToken`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': internalGetCookie("RefreshToken")
				},
				body: JSON.stringify({ remember: true })
			})
				.then((response) => {
					refreshTimeout = undefined;
					initializeSession(response);
				});
		}, refreshTokenTimeout);
	}
};

const removeLocalAuthData = () => {
	if (typeof window !== "undefined") {
		localStorage.removeItem('email');
		localStorage.removeItem('userId');
		localStorage.removeItem('lastRequestTime');
	}
	internalRemoveCookie('tokenExpirationDate');
	internalRemoveCookie('AuthorizationToken');
	clearTimeout(refreshTimeout);
	clearInterval(activityCheckInterval);
};

const destroySession = async () => {
	const authorizationHeader = internalGetCookie('AuthorizationToken');

	if (!authorizationHeader) {
		return Promise.resolve();
	}

	internalRemoveCookie('product');
	sessionStorage.removeItem('prevPath');

	removeLocalAuthData();
	fetch(`${APP_SERVER_URL}player/crm/logout`, {
		method: 'POST',
		headers: {
				'Content-Type': 'application/json',
		},
		body: JSON.stringify({}),
	})
	return true;
};

const redirectAfterRegistration = () => {
	AddOrRemoveSpinner(true);
	const userProduct = getUserProduct();
	switch (userProduct) {
		case AffiliateProduct.Sport:
			window.location.href = `${APP_WINPOT_CASINO}sport`;
			break;
		case AffiliateProduct.Casino:
		default:
			window.location.href = `${APP_WINPOT_CASINO}casino`;
			break;
	}
};

export {
	initializeSession,
	destroySession,
	redirectAfterRegistration,
	removeLocalAuthData,
	currentDomain
}