import { APP_WINPOT_CASINO, APP_SERVER_URL } from "../../../config.js";
import {checkIsSportUser} from "./trackingQueryHook";

const WINPOT_CASINO_URL = APP_WINPOT_CASINO;
const WINPOT_API_URL = APP_SERVER_URL;

const SCREEN_WIDTH = {
    MOBILE_XXS: 300,
    MOBILE_XS: 320,
    MOBILE_S: 360,
    MOBILE_M: 400,
    MOBILE_L: 414,
    MOBILE_XL: 480,
    TABLET_S: 567,
    TABLET_M: 599,
    TABLET_L: 700,
    TABLET_XL: 768,
    LAPTOP_M: 847,
    LAPTOP_L: 899,
    LAPTOP_XL: 1024,
    DESKTOP_XXS: 1156,
    DESKTOP_XS: 1199,
    DESKTOP_S: 1280,
    DESKTOP_M: 1366,
    DESKTOP_L: 1480,
    DESKTOP_XL: 1600,
    DESKTOP_XXL: 1800,
}

const EMAIL_REG_EXP =
    /^([a-zA-Z0-9ЁёáéíñóúüÁÉÍÑÓÚÜ]+([._+-]?[a-zA-Z0-9ЁёáéíñóúüÁÉÍÑÓÚÜ])+)@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+\.[a-zA-Z]{2,})$/;

const NAME_REG_EXP =
    /^([a-zA-ZáéíñóúüÁÉÍÑÓÚÜ]['\-,.]|[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ'\-. ])[^А-Яа-яЁё0-9\[\]_!¡?÷¿,№"\\/+=@^#$%ˆ\\&*(){}|~<>;:`]{1,}$/;

const PASSWORD_REG_EXP_WITHOUT_WHITESPACE =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=^\S+$)(?=.[^А-Яа-яЁёáéíñóúüÁÉÍÑÓÚÜ]*$)(?=.{8,30}$)/;

const NON_WHITESPACE_REG_EXP = /^(?=^\S+$)/;

const RUSSIAN_SPANISH_WORD = /[А-Яа-яЁёáéíñóúüÁÉÍÑÓÚÜ]/;

const MX_PHONE = /^(\+?52)?(1|01)?\d{10,11}$/;

const USERNAME_REG_EXP =
    /^([\w'\-,.]|[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ0-9'\-.])[^А-Яа-яЁё\\[\]!¡?÷¿.,№"`'\\/+=@^#$%ˆ&*(){}|~<>;:\-\s]{2,}$/;

const NAME_EMAIL_REGEX = new RegExp(`${EMAIL_REG_EXP.source}|${USERNAME_REG_EXP.source}`);

const LEGAL_AGE = 18;

const GtagEventNames = {
    LOGIN_FORM_LOGIN_CLICKED: "login_form_login_clicked",
    LOGIN_FORM_CLOSE_X_CLICKED: "login_form_close_x_clicked",
    LOGIN_FORM_FORGOT_PASSWORD_CLICKED: "login_form_forgot_password_clicked",
    LOGIN_FORM_CUSTOMER_SUPPORT_CLICKED: "login_form_customer_support_clicked",
    LOGIN_FORM_CREATE_ACCOUNT_CLICKED: "login_form_create_account_clicked",
    LOGIN_FORM_REMEMBER_ME_CLICKED: "login_form_remember_me_clicked",
    LOGIN_SUCCESS: "login_success",
    LOGIN_FAILURE: "login_failure",
};

const OPEN_SIGNUP_FORM_TIMEOUT_TIME = 10 * 1000; // 10 Seconds

export {
    WINPOT_CASINO_URL,
    SCREEN_WIDTH,
    EMAIL_REG_EXP,
    NAME_REG_EXP,
    PASSWORD_REG_EXP_WITHOUT_WHITESPACE,
    NON_WHITESPACE_REG_EXP,
    RUSSIAN_SPANISH_WORD,
    MX_PHONE,
    USERNAME_REG_EXP,
    NAME_EMAIL_REGEX,
    LEGAL_AGE,
    GtagEventNames,
    OPEN_SIGNUP_FORM_TIMEOUT_TIME,
    WINPOT_API_URL,
};
