import { WINPOT_CASINO_URL } from "../utils/consts";
import { getMobileOperatingSystem } from "../utils/utils";
import { checkIsSportUser } from "../utils/trackingQueryHook";

const setDownloadButtonData = () => {
    const os = getMobileOperatingSystem();
    const downloadItemElement = document.querySelector('.download-item');

    if (!downloadItemElement) return;

    const downloadLinkElement = downloadItemElement.querySelector('.download-link');
    let iconUrl, href, gtagEvent;

    if (os === "iOS") {
        iconUrl = 'assets/images/ios.svg';
        href = `https://apps.apple.com/mx/app/winpot-casino/id1632635281`;
        gtagEvent = 'sidemenu_item_download_ios';
        downloadItemElement.style.display = 'block';
    } else if (os === "Android") {
        iconUrl = 'assets/images/android.svg';
        href = checkIsSportUser() ? "https://play.google.com/store/apps/details?id=com.datawise.wpmain&pli=1" : `${WINPOT_CASINO_URL}app`;
        gtagEvent = 'sidemenu_item_download_android';
        downloadItemElement.style.display = 'block';
    } else {
        downloadItemElement.style.display = 'none';
        return;
    }

    if (downloadLinkElement) {
        const imgElement = downloadLinkElement.querySelector('img');
        if (imgElement) {
            imgElement.src = iconUrl;
        }
        downloadLinkElement.href = href;
        downloadLinkElement.setAttribute('data-gtag', gtagEvent);
    }
};

setDownloadButtonData();