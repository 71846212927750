import { checkIsSportUser } from "./trackingQueryHook.js";
import { APP_SERVER_URL, APP_WINPOT_CASINO } from "../../../config";

const getSiteConfig = async (type) => {
	const params = new URLSearchParams({ type });
	try {
		const response = await fetch(`${APP_SERVER_URL}site/config?${params}`);
		return await response.json();
	} catch (error) {
		throw new Error('Error:', error);
	}
};

export const openAppLink = (needLinkForIOSApp = true) => {
	const isSportUser = checkIsSportUser();
	if (needLinkForIOSApp) {
		const link = isSportUser
			? 'https://apps.apple.com/mx/app/winpot-sport/id6446956929'
			: 'https://apps.apple.com/mx/app/winpot-casino/id1632635281';
		window.open(link, '_blank');
	} else {
		if (isSportUser) {
			const link = 'https://play.google.com/store/apps/details?id=com.datawise.wpmain&pli=1';
			window.open(link, '_blank');
			return;
		}

		window.location.href = `${APP_WINPOT_CASINO}app`;
		// localStorage.setItem('isAppPanelVisible', Date.now().toString()); Mobile App Banner is unused on home page
	}
};
