import { getQueryParamFromUrl, openPopupById } from "../../utils/togglePopup";
import { OPEN_SIGNUP_FORM_TIMEOUT_TIME } from "../../utils/consts";
import { isLoggedIn, userHasAccount } from "../../utils/utils";

const signUpTimeout = () => {
    let timerId;
    timerId = setTimeout(() => {
        const modalFromUrl = getQueryParamFromUrl('modal');
        const signUpTimeoutCondition = !isLoggedIn() && !userHasAccount() && !modalFromUrl;
        if (signUpTimeoutCondition) {
            openPopupById("SignUp");
            clearTimeout(timerId);
        } else if (modalFromUrl) {
            signUpTimeout();
        }
    }, OPEN_SIGNUP_FORM_TIMEOUT_TIME);
}

window.addEventListener('pageshow', signUpTimeout);